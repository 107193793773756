<template>
  <div class="main">
    <Banner />
    <div class="search">
      <div class="search-content">
        <div class="search-left">
          <p>{{ $t('m.Keywords') }}</p>
          <ul>
            <li v-for="(item, index) in keywords" :key="index">
              {{ item.name }}
            </li>
          </ul>
        </div>
        <!-- <div class="search-right">
          <input type="text" placeholder="搜索您想要的电动机产品" />
          <div class="btn">
            <i class="el-icon-search"></i>
          </div>
        </div> -->
      </div>
    </div>
    <div class="goods">
      <div class="content">
        <h2>
          <span></span>
          <strong>5</strong>
          <span>{{ $t('m.FeaturesEnsuresHighQuality') }}</span>
        </h2>
        <div class="desc wow fadeIn" data-wow-duration="1s" data-wow-delay="1s">
          <p>
            {{ $t('m.functionIntroduction1') }}
          </p>
          <p>
						{{ $t('m.functionIntroduction2') }}
          </p>
        </div>
        <div class="list">
          <ul>
            <li v-for="(item, index) in goodsList" :key="index">
              <div class="imgBox" @click="showGoodsModal(item, index)">
                <img :src="item.img" alt />
              </div>
              <p>{{ item.goods }}</p>
              <p class="wow fadeIn" data-wow-duration="1s" data-wow-delay="1s">
                {{ item.desc }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="product">
      <div class="content">
        <h2 class="mb-40">
          <span class="blue">热销</span>
          <span>产品</span>
        </h2>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item
            title="纸"
            name="1"
            class="wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <ul class="list">
              <li v-for="(item, index) in productList" :key="index">
                <img :src="item.img" alt />
                <p>{{ item.name }}</p>
              </li>
            </ul>
          </el-collapse-item>
          <el-collapse-item title="pp透明膜" name="2"></el-collapse-item>
          <el-collapse-item title="pet膜" name="3"></el-collapse-item>
          <el-collapse-item title="水洗唛" name="4"></el-collapse-item>
        </el-collapse>
      </div>
    </div> -->
    <div class="blue-block">
      <div class="content">
        <h2>{{ $t('m.characteristic') }}</h2>
        <div>
          <!-- <p>7*24小时服务热线</p>
          <p>020-00000000</p> -->
        </div>
      </div>
    </div>
    <div class="reason">
      <div class="content" v-if="i18nlocale=='zh'">
        <h2 class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
          <span>{{ $t('m.Chooseour') }}</span>
          <strong>4</strong>
          <span>{{ $t('m.mainreasons') }}</span>
        </h2>
        <h3>
					{{ $t('m.details') }}
        </h3>
        <ul>
          <li
            class="wow fadeIn"
            v-for="(item, index) in reason"
            :key="index"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
						style="position: relative;"
          >
            <div class="photoBox">
              <img :src="item.img" alt />
              <div class="num">
                <span>0{{ index + 1 }}</span>
              </div>
            </div>
            <div class="desc">
              <p>{{ item.title }}</p>
              <p>{{ item.reason }}</p>
              <p>{{ item.desc }}</p>
            </div>
				
						<router-link :to="{name:'cloudPrint',params:{language:i18nlocale}}" v-if="index==3">
							<div  style="position: absolute;bottom:41px;left: 0;width: 116px;height: 38px;border: 1px solid #1E50AE;font-size: 18px;line-height: 38px;font-weight: Bold;text-align: center;color: #1E50AE;border-radius: 4px;cursor: pointer;" >
								了解更多
							</div>
					  </router-link>
          </li>
        </ul>
      </div>
			<div class="content" v-else>
        <h2 class="wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
          <span></span>
          <strong>Why us?</strong>
          <span></span>
        </h2>
        <h3>
					{{ $t('m.details') }}
        </h3>
        <ul>
          <li
            class="wow fadeIn"
            v-for="(item, index) in reason"
            :key="index"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
						style="position: relative;"
          >
            <div class="photoBox">
              <img :src="item.img" alt />
              <div class="num">
                <span>0{{ index + 1 }}</span>
              </div>
            </div>
            <div class="desc">
              <p>{{ item.title }}</p>
              <p>{{ item.reason }}</p>
              <p>{{ item.desc }}</p>
            </div>
						<router-link :to="{name:'cloudPrint',params:{language:i18nlocale}}" v-if="index==3">
							<div  style="position: absolute;bottom:41px;left: 0;width: 116px;height: 38px;border: 1px solid #1E50AE;font-size: 18px;line-height: 38px;font-weight: Bold;text-align: center;color: #1E50AE;border-radius: 4px;cursor: pointer;" >
								Learn more
							</div>
					  </router-link>
          </li>
        </ul>
      </div>

    </div>
    <div class="black-block">
      <div class="content">
        <h2>{{ $t('m.Weprovidereliable') }}</h2>
        <p>{{ $t('m.Throughcontinuous') }}</p>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="solution">
      <div class="content">
        <h2
          class="mb-60 wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <span>{{ $t('m.Weprovidesolutions') }}</span>
          <span class="blue"></span>
        </h2>
        <div class="list">
          <ul>
            <li
              v-for="(item, index) in solution"
              :key="index"
              class="list wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <img :src="item.img" alt="" />
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <!-- <div class="contactUs">
      <div class="content clearfix">
        <div
          class="title wow fadeIn"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        >
          <span>联系方式</span>
          <div class="line"></div>
        </div>
        <div class="msgList">
          <ul
            class="wow bounceInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <li><span>联系电话：</span><span>020-00000000</span></li>
            <li>
              <span>手机号码：</span><span>13000000000;100000000000</span>
            </li>
            <li>
              <span>传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;真：</span
              ><span>020-000-000</span>
            </li>
            <li>
              <span>邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱:</span
              ><span>131564315454@qq.com</span>
            </li>
            <li><span>联系QQ:</span><span>258506508</span></li>
            <li>
              <span>联系地址:</span><span>上海市青浦区达芙妮集团工厂821室</span>
            </li>
          </ul>
        </div>
        <div
          ref="myMap"
          id="map"
          class="wow bounceInRight"
          data-wow-duration="1s"
          data-wow-delay="0.5s"
        ></div>
      </div>
    </div> -->
    <!-- goods部分弹窗 -->
    <el-dialog
      :visible.sync="goodsVisible"
      width="360px"
      center
      :show-close="false"
      style="border-radius: 10px"
    >
      <div>
        <div class="imgBox">
          <img :src="goodsContent.img" alt="" />
        </div>
        <div class="title">
          <p>{{ goodsContent.goods }}</p>
          <span></span>
        </div>
        <div class="desc">
          {{ goodsContent.desc }}
        </div>
      </div>
      <img
        :src="require('@a/index/btns/left.png')"
        alt=""
        class="left"
        @click="changeCon('prev')"
        v-show="goodsCurrent == 0"
      />
      <img
        :src="require('@a/index/btns/leftActive.png')"
        alt=""
        class="left"
        @click="changeCon('prev')"
        v-show="goodsCurrent > 0"
      />
      <img
        :src="require('@a/index/btns/right.png')"
        alt=""
        class="right"
        @click="changeCon('next')"
        v-show="goodsCurrent == 4"
      />
      <img
        :src="require('@a/index/btns/rightActive.png')"
        alt=""
        class="right"
        @click="changeCon('next')"
        v-show="goodsCurrent < 4"
      />
      <img
        :src="require('@a/index/btns/back.png')"
        alt=""
        class="back"
        @click="() => (goodsVisible = false)"
      />
    </el-dialog>
  </div>
</template>

<script>
const Banner = () => import("@c/banner.vue");
import { WOW } from "wowjs";
import i18n from "@/lang"
export default {
  components: {
    Banner,
  },
  data() {
    return {
      goodsVisible: false, //goods部分弹窗
      goodsContent: {}, //goods部分弹窗内容
      goodsCurrent: 0,
      activeName: "1",
      // keywords: [
      //   { name: "热敏双色不干胶标签纸" },
      //   { name: "热敏水洗唛" },
      //   { name: "喷墨水洗唛" },
      //   { name: "热敏双面打印" },
      //   { name: "热敏pp透明膜" },
      // ],
      // goodsList: [
      //   {
      //     img: require("@a/index/goodslist/1.jpg"),
      //     goods: "即时打印双色标签",
      //     desc: "双色即时打印，黑色浓郁，传递日常信息；红色娇艳，凸显核心数据。无论是营销、还是管理，向用户传达你的心智。",
      //   },
      //   {
      //     img: require("@a/index/goodslist/2.jpg"),
      //     goods: "保存时长高达二十年",
      //     desc: "按照国家纸张检测中心检测标准，马斯夫热敏打印材料，平均保存时间高达二十年，达到碳带打印保存标准，低成本实现大价值。",
      //   },
      //   {
      //     img: require("@a/index/goodslist/3.jpg"),
      //     goods: "耐刮擦、耐高温",
      //     desc: "色牢度达到4级以上，标签经过运输或者水洗摩擦皆可长时间保存，温度达到90°，马斯夫材料不会自然发色，始终透亮。",
      //   },
      //   {
      //     img: require("@a/index/goodslist/4.jpg"),
      //     goods: "不卡纸",
      //     desc: "三层立体结构涂层方式，持久显色，涂层平滑不损伤打印头不粘头不卡纸，打印后纸张表面在高倍显微镜下无擦痕。",
      //   },
      //   {
      //     img: require("@a/index/goodslist/5.jpg"),
      //     goods: "防水、防油、防乙醇",
      //     desc: "水洗不掉、油浸不花，在油中浸泡3小时，字迹依然清晰可见，条码依然可以扫描。",
      //   },
      // ],
      productList: [
        {
          img: "https://28139699.s21i.faiusr.com/4/ABUIABAEGAAgn7zSjAYoyOukuQEwgQc4-ws!450x450.png.webp",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
        {
          img: "https://img2.baidu.com/it/u=4225631030,3055146044&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=751",
          name: "某某牌超好用的打印纸",
        },
      ],
      // reason: [
      //   {
      //     img: require("@a/index/reason/1.jpg"),
      //     title: "全产业链研发能力",
      //     reason: "深受世界众多知名企业的认可",
      //     desc: "马斯夫不仅拥有纳米级热敏高分子复合材料研发的多个自有产品，还拥有最新涂层工艺和改进的研发设备保障纸张、水洗唛等承印材料的生产品质。同时，马斯夫联合国内外顶尖打印机公司进行硬件控制程序和打印设备，更拥有国内一流软件研发团队，以打造基于云打印的物联网行业解决方案。",
      //   },
      //   {
      //     img: require("@a/index/reason/2.jpg"),
      //     title: "自有工厂、品控一流",
      //     reason: "工厂直销",
      //     desc: "马斯夫拥有自己的研发实验室、应用实验室与生产工厂，采用了特殊涂层技术和涂布工艺，全新定制产线，适用于各种纸张、膜类等生产。定期对工人开展各项培训，生产产品全程检验，精益求精做好产品品控。",
      //   },
      //   {
      //     img: require("@a/index/reason/4.jpg"),
      //     title: "规格多，品种齐全",
      //     reason: "满足您对于耗材的各种个性化需求",
      //     desc: "各种承印材料、打印设备综合研发，提供一体化服务。即时热敏打印的双色纸和双色PP、高透明度耐高温热敏PET、水洗不掉色的热敏水洗唛、双面热敏打印双胶纸、不洇墨喷墨基材......以上基材皆具备对应的打印设备作为支撑。",
      //   },
      // ],
      // solution: [
      //   {
      //     img: require("@a/index/solution/1.jpg"),
      //     name: "快递物流行业",
      //   },
      //   {
      //     img: require("@a/index/solution/2.jpg"),
      //     name: "家庭、商用打印行业",
      //   },
      //   {
      //     img: require("@a/index/solution/3.jpg"),
      //     name: "服装家居行业",
      //   },
      //   {
      //     img: require("@a/index/solution/4.jpg"),
      //     name: "日用品行业",
      //   },
      //   {
      //     img: require("@a/index/solution/5.jpg"),
      //     name: "餐饮行业",
      //   },
      //   {
      //     img: require("@a/index/solution/6.jpg"),
      //     name: "外卖POS行业",
      //   },
      // ],
    };
  },
	computed:{
		i18nlocale(){
			return i18n.locale
		},
		keywords(){
			return [
			  { name:this.$t('m.ThermosensitiveTwocolor') },
        // { name:this.$t('m.Sprayinktowashlabels') },
        { name:this.$t('m.doublesidethermalprinting') },
        { name:this.$t('m.transparentthermalppfilm') },
			]
		},
		goodsList(){
			return [
        {
          img: require("@a/index/goodslist/1.jpg"),
          goods: this.$t('m.DirectDualColorThermalPrinting'),
          desc: this.$t('m.functionIntroduction3'),
        },
        {
          img: require("@a/index/goodslist/2.jpg"),
          goods:this.$t('m.Shelflifeupto20Years'),
          desc: this.$t('m.functionIntroduction4'),
        },
        {
          img: require("@a/index/goodslist/3.jpg"),
          goods: this.$t('m.Scratchresistantand'),
          desc: this.$t('m.Colourfastness'),
        },
        {
          img: require("@a/index/goodslist/4.jpg"),
          goods:this.$t('m.NoPaperJam'),
          desc: this.$t('m.layerstructure'),
        },
        {
          img: require("@a/index/goodslist/5.jpg"),
          goods: this.$t('m.Waterproofoilresistant'),
          desc: this.$t('m.Theprintedpaper'),
        },
      ]
		},
		reason(){
			return [
        {
          img: require("@a/index/reason/1.jpg"),
          title: this.$t('m.capabilityindustrialchain'),
          reason: this.$t('m.Recognisedtheworld'),
          desc: this.$t('m.notonlyhasanumber'),
        },
        {
          img: require("@a/index/reason/2.jpg"),
          title: this.$t('m.ownfactory'),
          reason: this.$t('m.factoryoutlet'),
          desc: this.$t('m.laboratoryapplication'),
        },
        {
          img: require("@a/index/reason/4.jpg"),
          title: this.$t('m.Completespecification'),
          reason: this.$t('m.Meetyourunique'),
          desc: this.$t('m.Weprovideintegrated'),
        },
				{
					img: require("@a/index/reason/5.jpg"),
          title: this.$t('m.MASFCloudPrintingPlatform'),
          reason: this.$t('m.Cloudprintingmanagementplatform'),
          desc: this.$t('m.Thefirstdomesticdual'),
				}
      ]
		},
		solution() {
			return [
        {
          img: require("@a/index/solution/1.jpg"),
          name: this.$t('m.Logistics'),
        },
        {
          img: require("@a/index/solution/2.jpg"),
          name: this.$t('m.Homework'),
        },
        {
          img: require("@a/index/solution/3.jpg"),
          name: this.$t('m.ClothingandFurniture'),
        },
        {
          img: require("@a/index/solution/4.jpg"),
          name: this.$t('m.Consumergoods'),
        },
        {
          img: require("@a/index/solution/5.jpg"),
          name: this.$t('m.Catering'),
        },
        {
          img: require("@a/index/solution/6.jpg"),
          name: this.$t('m.TakeawayPOS'),
        },
      ]
		}
		
	},
  mounted() {
    // this.createMap();
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
  methods: {
    // 创建地图事件
    // createMap() {
    //   var map = new BMap.Map(this.$refs.myMap, {
    //     coordsType: 5, // coordsType指定输入输出的坐标类型，3为gcj02坐标，5为bd0ll坐标，默认为5。
    //   }); // 创建地图实例
    //   var point = new BMap.Point(121.1821, 31.152872); // 创建点坐标
    //   map.centerAndZoom(point, 18);
    //   map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    //   map.addControl(
    //     new BMap.NavigationControl({ anchor: BMAP_ANCHOR_TOP_RIGHT })
    //   );
    // },
    //打开goods部分弹窗事件
    showGoodsModal(item, index) {
      this.goodsVisible = true;
      this.goodsContent = item;
      this.goodsCurrent = index;
    },
    //切换弹窗内容事件
    changeCon(param) {
      //清空内容
      this.goodsContent = {};
      if (param == "next") {
        if (this.goodsCurrent < 4) {
          this.goodsCurrent += 1;
          this.goodsContent = this.goodsList[this.goodsCurrent];
        } else {
          this.goodsContent = this.goodsList[4];
        }
      } else {
        if (this.goodsCurrent > 0) {
          this.goodsCurrent -= 1;
          this.goodsContent = this.goodsList[this.goodsCurrent];
        } else {
          this.goodsContent = this.goodsList[0];
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-dialog__wrapper {
  /deep/.el-dialog {
    border-radius: 10px;
    position: relative;
    .el-dialog__body {
      padding: 10px 10px 34px 10px;
      .imgBox {
        img {
          width: 340px;
          height: 340px;
        }
      }
      .title {
        font-size: 18px;
        color: #0a0a0a;
        line-height: 31px;
        text-align: center;
        position: relative;
        p {
          font-weight: bold;
        }
        span {
          position: absolute;
          width: 21px;
          height: 4px;
          background: #1e50ae;
          border-radius: 2px;
        }
      }
      .desc {
        padding: 0 4px;
        margin-top: 13px;
        color: #0a0a0a;
        line-height: 22px;
        min-height: 66px;
      }
    }
    .left {
      position: absolute;
      left: -100px;
      top: 180px;
    }
    .right {
      position: absolute;
      right: -100px;
      top: 180px;
    }
    .back {
      position: absolute;
      bottom: -48px;
      left: 154px;
    }
  }
}

.clearfix:after {
  content: ""; /*设置内容为空*/
  height: 0; /*高度为0*/
  line-height: 0; /*行高为0*/
  display: block; /*将文本转为块级元素*/
  visibility: hidden; /*将元素隐藏*/
  clear: both; /*清除浮动*/
}
.clearfix {
  zoom: 1; /*为了兼容IE*/
}
#map ::v-deep.BMap_cpyCtrl {
  display: none;
}
#map ::v-deep .anchorBL {
  display: none;
}
.main {
  .content {
    h2 {
      float: center;
      text-align: center;
      &.mb-40 {
        margin-bottom: 40px;
      }
      &.mb-60 {
        margin-bottom: 60px;
      }
      span {
        font-size: 30px;
        color: rgb(53, 53, 53);
        font-weight: bold;
        &:nth-of-type(1) {
          position: relative;
          &::before {
            position: absolute;
            left: -120px;
            top: 50%;
            width: 100px;
            height: 1px;
            background: #dfdfdf;
            content: "";
          }
        }
        &:nth-of-type(2) {
          position: relative;
          &::after {
            position: absolute;
            right: -120px;
            top: 50%;
            width: 100px;
            height: 1px;
            background: #dfdfdf;
            content: "";
          }
        }
        &.blue {
          color: rgb(30, 80, 174);
        }
      }
      strong {
        color: rgb(30, 80, 174);
        font-size: 70px;
        font-style: italic;
        margin: 10px;
      }
    }
  }
  .search {
    background: rgb(247, 247, 247);
    .search-content {
      width: 1200px;
      height: 90px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .search-left {
        display: flex;
        font-size: 14px;
        p {
          color: rgb(0, 0, 0);
        }
        ul {
          display: flex;
          color: rgb(143, 143, 143);
          li {
            margin-left: 20px;
          }
        }
      }
      .search-right {
        display: flex;
        input {
          width: 245px;
          height: 40px;
          border: 1px solid #ddd;
          padding-left: 10px;
          &::placeholder {
            color: rgb(191, 191, 191);
          }
        }
        .btn {
          background-color: #1e50ae;
          width: 75px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
  .goods {
    background: #fff;
    .content {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 30px;
      padding-top: 50px;
      box-sizing: border-box;
      .desc {
        text-align: center;
        font-size: 12px;
        line-height: 24px;
        color: rgb(191, 191, 191);
      }
      .list {
        ul {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 160px;
            text-align: center;
            .imgBox {
              width: 190px;
              height: 190px;
              border-radius: 50%;
              background: #fff;
              position: relative;
              box-shadow: 0px 0px 10px #eee;
            }
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              margin-left: -90px;
              margin-top: -90px;
              border-radius: 50%;
              width: 180px;
              height: 180px;
              transition: all 0.4s ease;
              &:hover {
                -webkit-transform: scale(1.1, 1.1);
                -moz-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1);
              }
            }
            p {
              width: 222px;
              &:nth-of-type(1) {
                color: rgb(53, 53, 53);
                font-size: 18px;
                margin: 15px 0;
              }
              &:nth-of-type(2) {
                text-align: center;
                font-size: 14px;
                color: rgb(119, 119, 119);
              }
            }
          }
        }
      }
    }
  }
  .product {
    background: #f7f7f7;
    .content {
      width: 1200px;
      margin: 0 auto;
      padding: 100px 0 0 0;
      height: 1080px;
      box-sizing: border-box;
      ::v-deep.el-collapse-item__content {
        padding-bottom: 10px;
      }
      ::v-deep.el-collapse-item__header {
        padding-left: 20px;
        font-size: 16px;
        &.is-active {
          color: #ffffff;
          background: #1e50ae;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 34px 21px 0;
        li {
          width: 260px;
          margin: 5px 10px;
          img {
            width: 100%;
            height: 260px;
            border: 1px solid rgb(240, 238, 238);
            cursor: pointer;
            transition: all 0.4s ease;
            image-rendering: -webkit-optimize-contrast;
            //object-fit: none;//当屏幕尺寸小于图片大小的时候，图片会自动裁剪至中间位置
            &:hover {
              -webkit-transform: translate(0, -10px);
              -moz-transform: translate(0, -10px);
              transform: translate(0, -10px);
            }
          }
          p {
            text-align: center;
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }
  }
  .blue-block {
    background: url(//20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAg1LWQ7QUo_LGhJzCADzi0AQ.jpg)
        repeat top,
      transparent;
    .content {
      width: 1200px;
      margin: 0 auto;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 180px;
      h2 {
        font-weight: bold;
        font-style: italic;
        font-size: 36px;
      }
      div {
        p {
          &:nth-of-type(2) {
            font-size: 16px;
            font-weight: bold;
          }
          &:nth-of-type(2) {
            font-size: 30px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .reason {
    .content {
      width: 1200px;
      margin: 0 auto;
      h3 {
        font-size: 12px;
        color: rgb(191, 191, 191);
        text-align: center;
        line-height: 40px;
        margin-bottom: 30px;
      }
      ul {
        li {
          height: 294px;
          margin-bottom: 80px;
          &:nth-of-type(odd) {
            .photoBox {
              float: left;
              width: 558px;
              position: relative;
            }
            .desc {
              float: right;
            }
          }
          &:nth-of-type(even) {
            .photoBox {
              float: right;
              position: relative;
            }
            .desc {
              float: left;
            }
          }
          img {
            height: 294px;
            width: 558px;
            image-rendering: -webkit-optimize-contrast;
            // transition: all 0.2s ease-out;
            // &:hover {
            //   //图片放大效果
            //   -webkit-transform: scale(1.1, 1.1);
            //   -moz-transform: scale(1.1, 1.1);
            //   transform: scale(1.1, 1.1);
            //   //图片上移效果
            //   -webkit-transform: translate(0, -10px);
            //   -moz-transform: translate(0, -10px);
            //   transform: translate(0, -10px);
            // }
          }
          .num {
            position: absolute;
            left: 26px;
            top: -36px;
            width: 88px;
            height: 111px;
            background: rgb(30, 80, 174);
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              display: block;
              height: 56px;
              font-size: 40px;
              font-weight: bold;
              border-bottom: 5px solid #fff;
              width: 52px;
              text-align: center;
            }
          }
          .desc {
            width: 548px;
            p {
              &:nth-of-type(1) {
                // margin-top: 40px;
                font-size: 30px;
                color: rgb(30, 80, 174);
                font-weight: bold;
              }
              &:nth-of-type(2) {
                color: rgb(153, 153, 153);
                font-size: 14px;
                margin: 10px 0 20px 0;
              }
              &:nth-of-type(3) {
                font-size: 14px;
                color: rgb(89, 89, 89);
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
  .black-block {
    background: url(//20601220.s61i.faiusr.com/2/AD0IhLPpCRACGAAgx7_Q7QUoqKGK1gUwgA84tAE.jpg)
        repeat top,
      transparent;
    .content {
      width: 1200px;
      margin: 0 auto;
      height: 180px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 36px;
        color: rgb(30, 80, 174);
        font-weight: bold;
      }
      p {
        font-size: 16px;
        color: rgb(107, 107, 107);
        margin-top: 10px;
      }
    }
  }
  .solution {
    .content {
      width: 1200px;
      height: 887px;
      margin: 0 auto;
      padding-top: 100px;
      box-sizing: border-box;
      .list {
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0 0 10px 0;
            p {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
  .contactUs {
    .content {
      width: 1200px;
      background: #f7f7f7;
      margin: 0 auto;
      padding: 105px 0 78px 110px;
      //height: 490px;
      box-sizing: border-box;
      .title {
        font-size: 30px;
        color: rgb(53, 53, 53);
        height: 71px;
        position: relative;
        .line {
          position: absolute;
          background: rgb(30, 80, 174);
          width: 50px;
          height: 2px;
          top: 50px;
          left: 0;
          display: block;
        }
      }
      .msgList {
        font-size: 16px;
        line-height: 46px;
        color: #666;
        width: 400px;
        float: left;
        ul {
          li {
            span {
              &:nth-of-type(1) {
                width: 80px;
                display: inline-block;
              }
            }
          }
        }
      }
      #map {
        float: left;
        //background: red;
        width: 500px;
        height: 300px;
        margin-left: 100px;
      }
    }
  }
}
</style>